@import "main/styles/index.less";
.add-money-dialog {
  background-color: #2c2c2c;
  border: 12px solid #aeaeae;
  padding: 25px;
  text-align: center;
  width: 475px;
  @media @phone-only {
    padding: 10px;
    width: 100%;
  }

  .icon-arrow-left {
    color: @dialog-control-color;
    position: absolute;
    left: 25px;
    top: 25px;
  }

  .icon-close {
    color: @dialog-control-color;
    position: absolute;
    right: 25px;
    top: 25px;
  }

  h2 {
    color: @white;
    font-family: gotham_bookregular;
    font-size: 14px;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
    padding-top: 0;
    margin-top: -10px;
    @media @phone-only {
      margin-top: 5px;
    }
  }
  p {
    color: @lighter-grey;
    font-family: Georgia;
    font-size: 14px;
    letter-spacing: 1px;
    margin: 30px 24px 30px 24px;
    @media @phone-only {
      font-size: 11px;
    }
  }
  .add-money-amount {
    width: 100%;
    background-color: #72bdda;
    &:before {
      border-top-color: @black-text;
    }
    select {
      color: @black-text;
      text-transform: uppercase;
    }
  }
  .btn-add-money {
    color: @white;
    display: block;
    letter-spacing: 1px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 20px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }
  .fund-payment-form {
    text-align: left;
    .column-1, .column-2 {
      float: left;
      width: 100%;
    }
    .content-box-content {
        background-color: #2c2c2c;
        color: #ffffff;
        padding: 30px 0 0 0;
    }
    .convert-message {
        margin-top: 0;
    }
  }
  .terms {
    font-size: x-small;
    color: #aeaeae;
    text-align: left;
  }
}

.tip-dialog {
  background-color: #2c2c2c;
  border: 12px solid #aeaeae;
  padding: 25px;
  text-align: center;
  width: 475px;
  @media @phone-only {
    padding: 10px;
    width: 100%;
  }

  .icon-arrow-left {
    display: none;
    color: @dialog-control-color;
    position: absolute;
    left: 25px;
    top: 25px;
  }

  .icon-close {
    color: #b4b4b4;
    position: absolute;
    right: 25px;
    top: 25px;
  }

  h2 {
    color: @white;
    font-family: gotham_boldregular;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    margin-top: 40px;
    text-transform: uppercase;

    &:before, &:after {
      color: @medium-grey;
    }

    .amount-heading {
      display: none;
    }
  }

  .tip-amounts {
    display: flex;
    justify-content: space-between;

    @media @phone-only {
      flex-wrap: wrap;
    }

    > button {
      background-color: #2c2c2c;
      border: 1px solid @medium-grey;
      color: @white;
      flex-basis: 15%;
      font-family: gotham_boldregular;
      font-size: 18px;
      letter-spacing: 2px;
      margin-top: 45px;
      padding: 10px;

      @media @phone-only {
        flex-basis: 27%;
        margin-top: 30px;
      }

      &.selected-amount, &.selected-amount:hover {
        background-color: @darker-green;
      }

      &:hover {
        background-color: @medium-grey;
      }

      &:disabled {
        color: @medium-grey;
        &:hover {
          background-color: #2c2c2c;
        }
      }

      &:last-child {
        color: @pink;
      }
    }
  }
  .tip-custom-amount {
    display: none;
    margin-top: 70px;

    .flex-container {
      display: flex;
      align-items: center;

      div {
        &:first-child {
          font-family: gotham_boldregular;
          font-size: 30px;
          padding: 15px;
        }
        &:last-child {
          flex-grow: 1;
        }
      }
    }
  }
  .current-balance {
    border-top: 1px solid @medium-grey;
    display: flex;
    font-family: gotham_boldregular;
    font-size: 11px;
    justify-content: space-between;
    margin-top: 25px;
    padding-top: 25px;
    text-transform: uppercase;

    span {
      color: @white;
      .current-balance-amount {
        color: @pink;
      }
    }
  }
  .tip-message-label {
    color: @white;
    font-family: gotham_boldregular;
    font-size: 11px;
    margin-top: 40px;
    text-transform: uppercase;
    text-align: left;
  }
  .tip-message {
    height: 100px;
    margin-top: 20px;
    text-align: left;
  }
  .tip-message:not(.active):before {
    content: attr(placeholder);
    color: #aaa;
  }
  .tip-privacy {
    margin-top: 20px;

    ul {
      display: flex;
      justify-content: space-between;
      text-align: left;

      @media @phone-only {
        flex-direction: column;
      }

      li {
        background: #474747;
        color: @white;
        font-family: gotham_bookregular;
        font-size: 12px;
        letter-spacing: 1px;
        padding: 10px;
        text-transform: uppercase;
        width: 198px;

        @media @phone-only {
          margin-bottom: 4px;
          padding: 20px;
          width: 100%;
        }

        .icon-checkmark {
          &:before {
            background-color: @pink;
            border-radius: 10px;
            color: @white;
            font-size: 10px;
            height: 10px;
            padding: 4px;
            margin-right: 15px;
            width: 10px;
            @media @phone-only {
              border-radius: 15px;
              padding: 5px;
              font-size: 12px;
            }
          }
        }
        &:not(.selected) .icon-checkmark {
          &:before {
            background-color: #858585;
            color: #858585;
          }
        }
      }
    }
  }
  .btn-confirm-tip {
    background-color: @darker-green; //#6e8928;
    color: @white;
    display: block;
    font-size: 14px;
    letter-spacing: 1px;
    margin-top: 45px;
    padding: 20px;
    width: 100%;
    &:disabled {
      background-color: #414141;
      color: #828282;
    }
  }
}

.feed,
.article-feed {
  .tip-received {
    .thumb {
      display: block;
      float: left;
      height: 70px;
      width: 70px;
      img {
        display: block;
      }
    }
    .profile .thumb {
      height: 45px;
      width: 45px;
    }
    .anonymous {
      float: left;
      height: 70px;
      width: 70px;
      &:before {
        content: '\e089';
        background-color: @darker-green;
        color: @white;
        display: inline-block;
        font-family: SG;
        font-size: 22px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        transition: none;
        width: 70px;
      }
    }
    .profile .anonymous {
      height: 45px;
      width: 45px;
      &:before {
        font-size: 18px;
        height: 45px;
        line-height: 45px;
        width: 45px;
      }
    }
    .header h2.top-bar {
      padding: 17px 5px 17px 80px;
      font-size: 11px;
      color: #656565;
      a, span { color: #fff;}
      .tip-amount {
        color: @pink;
        display: block;
        font-size: 18px;
        margin-top: 3px;
      }
    }
    .header.profile h2.top-bar {
      padding-left: 55px;
      padding-bottom: 17px;
    }
  }
}
