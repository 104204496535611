@import "main/styles/index.less";
/* Profile Search Classes */

#advanced-search-form {
  display: none;
}
.fancybox-inner{
  height:auto !important;
}
.fancy-wrap{
  width:360px;
  ul{
    margin:0px;
    padding:0px;
    li{
      list-style:none;
      display:block;
      background:#eee;
      border-bottom: 1px solid #d3d3d3;
      display:table;
      .vote-inner-table{
        display:table-row;
      a{
        width:70px;
        display:table-cell;
        vertical-align:middle;
      }
      .fancy-right{
        display:table-cell;
        padding-left: 20px;
        margin-top:15px;
        width: 290px;
        vertical-align: middle;
        @media @phone-only {
          padding-left:10px;
          width: 220px;
        }
      }
      .comment-text{
        font-family: gotham_boldregular;
        letter-spacing: 1px;
        text-transform: uppercase;
        line-height: 20px;
        text-decoration: none;
        word-wrap: break-word;
        display: block;
        color: #d7849a;
        a{
          width:100% !important;
          display: block;
          height: auto !important;
        }
      }
      .fancy-time{
        font-family: gotham_boldregular;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #979797;
        font-size:10px;
        line-height: 20px;
        text-decoration: none;
        word-wrap: break-word;
      }
     }
    }
  }
  @media @phone-only {
        width: 290px;
  }
}
.vote-fancybox-width{
  width:360px;
    @media @phone-only {
      width: 290px;
    }
}
.fancybox-title {
    background-color: #252525;
    color: #6e6e6e;

    span {
    float: right;
    margin-right: 10px;
    font-size: 10px;
      font-family: gotham_bookregular;
      text-transform:uppercase;
  }
  .adv-search{
    width: 84%;
    display: inline-block;
    padding: 13px 30px 20px 0;
    text-align: center;
    height:auto;
    font-family: gotham_boldregular;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #d3d3d3;
    font-size: 10px;
    line-height: 20px;
    text-decoration: none;
    word-wrap: break-word;
  }
  .icon-heart:before{
    display: block;
    color: #d7849a;
    font-size: 17px;
    margin-bottom: 5px;
  }

  a {
    float: left;
    margin-left: 10px;
    color: #6e6e6e;
  }
  .icon-close:before{
    font-size:20px;
    padding-top:10px;
    padding-left:6px;
  }
}
.load-more-popup a{
    text-align: center;
    background: #de879e;
    padding: 18px;
    display:block;
    color:#fff;
    font-family: gotham_boldregular;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
    cursor: pointer;
}
.load-more-popup a:hover{
  background:#fff;
  color: #de879e;
}
.fancybox-skin {
  background-color: black;
}

.firefox-container {
  a {
    background-color: #1d5e75;
  }
}

.empty {
  display: none;
}

#advanced_search_toggle_mobile {
  background-color: #1d5f77;
  color: #ced7de;
  &.active {
    color: #c3788c;
  }
  span {
    margin-right: 10px;
  }
}


@standard_choice_height: 45px;
@left_padding: 18px;

#advanced-search-form {
  background: black;
  padding: 20px;
  color: #fff;
  margin-bottom: 30px;
  text-transform:uppercase;
  h2 {
    color: #fff;
    margin: 0;
    margin-bottom: 10px;
    clear: both;
    letter-spacing: 1px;
    font-size: 11px;
    font-weight: bold;
    font-family: gotham_boldregular;
  }
  .sel {
    margin-bottom: 20px;
    background-color: black;
    select {
      background: #72bdda;
      font-size: 12px;
      font-family: gotham_boldregular;
      font-weight: normal;
      letter-spacing: 2px;
      color: black;
      text-transform:uppercase;
      border-radius: 0;
      -moz-appearance: none;
      text-indent: 18px;
      text-overflow: '';
      height: @standard_choice_height;
    }

    &:before {
      border-top-color: black;
      top: 35px;
      z-index: 3;
      right: 20px;
    }
  }

  .about {
    margin-bottom: 20px;
    margin-top: 30px;
  }
  .checkboxes {
    label {
      font-size: 11px;
      font-family: gotham_bookregular;
      cursor: pointer;
      display: block;
      padding-left: 0;
      text-align: left;
      white-space: nowrap;
    }
    ul {
      .clearfix;
      clear: both;
      margin-bottom: 20px;
      width: 100%;
    }

    li {
      margin-bottom: 20px;
      &:nth-child(even) {
        float: right;
      }
      &:nth-child(odd) {
          float: left;
          clear: right;
      }
      input {display: none;}

      label {
        font-family: gotham_bookregular;
        cursor: pointer;
      }
      label:before {
        content: '';
        .icon-font;
        font-size: 10px;
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        padding: 5px;
        color: #fff;
        .border-radius(10px);
        background: #9d9d9d;
        position: relative;
        right: 7px;
        bottom: 5px;
      }
      input:checked + label:before {
        background: #93b867;
        content: "\e020";
      }
    }

    h2 {
      background: transparent;
      padding: 0;
      margin: 0 0 20px 0;
    }
  }

  .location {
    height: 85px;
    input[type='checkbox'] {
      right: 0;
    &:before {
      bottom: 5px;
      right: 5px;
      }
    }
    .sel {
      clear: both;
      display: block;
      width: 100%;
      select {
        width: 100%;
      }

      &:before {
        top: 18px;
      }
    }
  }

  .age {
    font-size: 10px;
    .clearfix;
    margin-bottom: 20px;

    .sel {
      float: left;
      width: 49%;
      margin-right: 1px;
    }

    label {
      width: 60px;
      text-align: center;
      line-height: 60px;
    }
    label[for="id_age_to"] {
      font-family: gotham_bookregular;
    }
  }


  #id_zipcode {
    width: 100%;
    min-width: 75px;

    outline: none;
    margin: 0;
    border: none;
    border-bottom: 3px dotted #fff;
    padding: 10px 0;
    background: transparent;
    font-family: gotham_boldregular;
    text-transform: uppercase;
    font-size: 13px;
    color: white;
  }

  #id_miles {
    width: 100%;
    min-width: 170px;
  }

  .miles_within {
    label {
      color: #fff;
      display: block;
      margin-bottom: 10px;
      font-size: 11px;
      font-weight: bold;
      font-family:  gotham_boldregular, Helvetica, sans-serif;
    }

    .sel {
      @media @phone-only {
        width: 100%;
      }
    }

    .column-2 {
      @media screen and (max-width: 1024px) {
        width: 50%;
      }
      @media @phone-only {
        width: 100%;
      }
    }

  }



  .about {

    .drop-select {
      .key {
        color: black;
        line-height: 13px;
      }
      .value {
        color: black;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      background-color: #72bdda;
      .dropdown-menu li {
        background-color: #4390AC;
        color: black;
      }
      .dropdown-menu li:hover {
        color: #07475E;
      }

      &:after {
        border-top-color: black;
      }
    }

  }

  .delete-toggle-cross-inner {
    background-color: #60abc4;
    font-family: 'SG';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    margin: 10px;
    padding: 7px;
    padding-left: 8px;
    .border-radius(25px);
    position: relative;
  }

  .drop-select {

    .delete-toggle {
      z-index:s 100;
      display: none;
      @media @phone-only {
        display: block;
      }
      height: @standard_choice_height;
      width: @standard_choice_height;

      float: left;
      background-color: #458ea8;
      &:hover {
        color: #99C0CA;
        background-color: #286D86;
        .delete-toggle-cross-inner {
          background-color: #4A92AD;
        }
      }
    }

    &:hover {
      .delete-toggle {
        display: block;
      }
    }
  }

  #section_wrapper_pigeonholes .tag-container {
    margin-bottom: 20px;
  }


  .content-box-content {
    padding: 5px;
    background: black;
    color: white;

    .title {
      text-transform: uppercase;
      font-family: gotham_bookregular;
      font-size: 13px;

      &:before {
        .icon-font;
        content: "+";
        position: relative;
        float: right;
        z-index: 1;
        top: -32px;

        font-size: 20px;
        font-weight: bold;
        display: block;

        width: 20px;
        height: 20px;

        text-align: center;
        vertical-align: middle;
        line-height: 14px;

        color: #fff;
        .border-radius(15px);
        background: #93b867;
        cursor: pointer;
      }
    }

    .delete-toggle {
      color: #d991a4;
      font-size: 10px;
      text-transform: uppercase;
      font-family: gotham_bookregular;
      float: right;
      margin-right: -20px;

      &:hover {
        color: white;
      }
    }

    .tag {
      font-size: 11px;
      font-family: gotham_boldregular;
      background-color: #d792a4;
      &:after {
        content: "\e02a";
        font-family: 'SG';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
      }
    }

    input[type=text] {
      outline: none;
      margin: 0;
      border: none;
      border-bottom: 3px dotted #fff;
      padding: 10px 0;
      background: transparent;
      font-family: gotham_boldregular;
      text-transform: uppercase;
      font-size: 13px;
      color: white;
    }
  }

  #add-criteria {
    background-color: #1d5f77;
    color: white;
    line-height: 22px;

    &:before {
      border-top-color: white;
      right: 20px;
      top: 22px;
    }
  }


  #profile-criteria {
    height: 100%;
  }

  #profile-criteria > ul {
    position: relative;
    > li a {
      background-color: #07475E;
      color: white;
      border-top: 1px solid black;
      border-bottom: none;
    }
  }

  #profile-criteria > ul > li a:hover {
    color: #72bdda;
  }


  @media @phone-only {
    .horizontal-nav {
      display: table !important;

      margin-bottom: 12px;
      > ul {
        &:first-child a:before {
            display: none;
        }
        display: block;
        > li {
          display: block;
        }

      }
      &:before {
        content: ' ';
        #arrow .down(10px; 10px; #fff);
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 20px;
        z-index: 1000;
      }
    }

    .horizontal-nav .active {
      ul {
        display: block;
        > li {
          display: block;
        }
      }
    }
  }



  .button-wrapper {
    .call-to-action {
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-family: gotham_boldregular;

    }
    button {
      padding: 0;
      height: 54px;
      line-height: 54px;
    }
  }
}
