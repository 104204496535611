@import "lesshat.less";

@phone-only: ~"screen and (max-width: 599px)";
@phone: ~"screen and (min-width: 600px)";
@phone-to-landscape: ~"screen and (min-width: 600px) and (max-width: 1023px)";
@ipad: ~"screen and (min-width: 768px)";
@paddingBuffer: ~"screen and (min-width: 769px) and (max-width: 875px)";
@ipadmax: ~"screen and (max-width: 767px)";
@landscape: ~"screen and (min-width: 1024px)";
@underlandscape: ~"screen and (max-width: 1023px)";
@maximum: ~"screen and (min-width: 1216px)";
@lessThanMax: ~"screen and (max-width: 1216px)";
@paddingMaximum: ~"screen and (min-width: 1376px)";
@overIpad: ~"screen and (min-width: 769px)"; /* only applies to screens > ipad screen res (768px) */
@beforeIpad: ~"screen and (max-width: 767px)";
@overIpadLanscape: ~"screen and (min-width: 1025px)";
@phoneLandscape: ~"screen and (max-width: 1024px) and (orientation: landscape)";
@iphone5: ~"screen and (max-width: 374px) and (orientation: portrait)";
/* iPhones 6,7, and 8 regular, *not* Plus: */
@iphone6: ~"screen and (max-width: 414px) and (orientation: portrait)";

@pink: #de879e;
@orange: #DCB058;
@grey-text: #979797;
@grey-text-darker: #727272;
@background-grey: #eeeeee;
@background-dark-grey: #b3b3b3;
@background-shaded-grey: #e3e3e3;
@green: #93b868;
@green-hover: #5f8b32;
@darker-green: #6e8928;
@yellow: #ddaf57;
@yellow-hover: #b98d38;
@medium-dark-grey: #414141;
@dark-grey: #252525;
@dark-purple: #3a3134;
@darker-pink: #c3788c;
@fixedColumnWidth: 270px;
@red: #cc4748;
@yellow: #dcb058;
@header: 16px;
@subHeader: 10px;
@content: 14px;
@horizontalNav: 12px;
@button: 12px;
@light-grey: #989898;
@lighter-grey: #d9d9d9;
@medium-grey: #515151;
@white: #ffffff;
@black-text: ~'rgba(37, 37, 37)';
@checkmark-background: #cccccc;

@dialog-control-color: #b4b4b4;
@active-tab-bg: #000000;

  // mixins
  .icon-font() {
    -webkit-font-smoothing: antialiased;
    font-family: 'SG';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
  }

  .no-tap() {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent !important;
  }
  .dark-background {
      background: #191919;
      color: #ffffff;
  }

  .title-text() { font-family: gotham_boldregular; letter-spacing: 1px; text-transform: uppercase; }
  .body-text() {
    font-family: Georgia, serif;
    text-transform: none;
  }
  .absolute() {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .transition-opacity() {
    -webkit-transition: opacity .2s ease-out;
    -o-transition: opacity .2s ease-out;
    -ms-transition: opacity .2s ease-out;
    -moz-transition: opacity .2s ease-out;
    transition: opacity .2s ease-out;
  }

  .transition-all() {
    -webkit-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    -ms-ansition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }

  .no-transition() {
    -webkit-transition: none;
    -o-transition: none;
    -ms-transition: none;
    -moz-transition: none;
    transition: none;
  }

  .ellipsis() {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }


#arrow {

  .base() {
    content: "\0020";
    display: block;
    width: 0;
    height: 0;
    border: 0 none;
    border-style: solid;
  }

  .up( @arrow-width, @arrow-height, @arrow-color ) {
    .base();
    border-width: 0 @arrow-width @arrow-height @arrow-width;
    border-color: transparent;
    border-bottom-color: @arrow-color;
  }

  .right( @arrow-width, @arrow-height, @arrow-color ) {
    .base();
    border-width: @arrow-width 0 @arrow-width @arrow-height;
    border-color: transparent;
    border-left-color: @arrow-color;
  }

  .down( @arrow-width, @arrow-height, @arrow-color ) {
    .base();
    border-width: @arrow-height @arrow-width 0 @arrow-width;
    border-color: transparent;
    border-top-color: @arrow-color;
  }

  .left( @arrow-width, @arrow-height, @arrow-color ) {
    .base();
    border-width: @arrow-width @arrow-height @arrow-width 0;
    border-color: transparent;
    border-right-color: @arrow-color;
  }

}


.sitewide-piping() {
  .top {
    margin-top: 12px;
    @media @landscape {
      margin-top: 30px;
    }
  }

  .bottom {
    margin-bottom: 12px;
    @media @landscape {
      margin-bottom: 30px;
    }
  }

  .left {
    margin-left: 12px;
    @media @landscape {
      margin-left: 30px;
    }
  }

  .right {
    margin-right: 12px;
    @media @landscape {
      margin-right: 30px;
    }
  }
}

.decorated() {
  position: relative;
  margin-bottom: 50px;
  &:before, &:after {
    .icon-font;
    color: inherit;
    position: absolute;
    left: 0;
    font-size: 25px;
    width: 100%;
    text-align: center;
  }
  &:before {
    content: "\e001";
    top: -40px;
  }
  &:after {
    content: "\e002";
    bottom: -40px;
  }
  @media @underlandscape {
    margin-bottom: 0.5em !important;
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }
}

.decorate-top() {
  &:before {
    .icon-font;
    content: "\e001";
    top: -40px;
    color: inherit;
    position: absolute;
    left: 0;
    font-size: 32px;
    width: 100%;
    text-align: center;
  }
}

.decorate-bottom() {
  &:after {
    content: "\e002";
    bottom: -40px;
    color: inherit;
    position: absolute;
    left: 0;
    font-size: 32px;
    width: 100%;
    text-align: center;
    .icon-font;
  }
}

.clearfix() {
  &:before,
  &:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }

  &:after {
    clear: both;
  }
}

.visuallyhidden() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// select boxes small variant mixin
.sel-small() {
  &:before {
    #arrow .down( 7px; 7px; #fff );
    top: 19px;
  }
  select {
    margin: 13px 0;
  }
}


/* content layout global and columns */
.hidden {
  display: none;
}

.left {
  float: left;
}

.right {
  float: right;
}
