@import "main/styles/index.less";
body.search {
  .group-container {
      article {
      width: 100%;
      @media @phone {
        &:nth-child(odd) {
          padding-right: 6px;
          clear: left;
        }
        &:nth-child(even) {
          padding-left: 6px;
        }
        float: left;
        width: 50%;
      }
      @media @landscape {
        &:nth-child(odd) {
          padding-right: 15px;
          clear: left;
        }
        &:nth-child(even) {
          padding-left: 15px;
        }

      }

    }
  }

  .content-box-content .comment-thumb {
    margin-right: 20px;
  }

  #search-form-inline {
    .clearfix;
    background: #eee;
    .sitewide-piping .bottom;
    & > div {

      width: 50%;
      float: left;
      padding: 30px;
      font-size: 12px;
      .title-text;
      label {
        font-size: 18px;
        margin-bottom: 5px;
      }
      &:first-child {
        border-right: 1px solid white;
      }
      .step-slider-3 {
        height: 50px;
      }
      @media @phone-only {
        width: 100%;
        border-bottom: 1px solid white;
      }
      .sel {
        width: 100%;
        .title-text;
        background: #c1c1c1;
        select {
          text-transform: uppercase;
          width: 100%;

        }
      }
      .slider-setting {
        padding: 10px 0 40px 0;
        ul {
          margin-left: 35px;
          & li:first-child {
            top: 0px;
          }

          & li:last-child {
            top: 47px;
          }

        }
      }
    }
    .sel {

    }
    &.in-group .sel {
      margin-top: 50px;
    }
  }



  .result-section {
    .clearfix;
    h1 {
      .title-text;
      background: #eee;
      color: #2a2a2a;
      line-height: 65px;
      font-size: 24px;
      letter-spacing: 3px;
      position: relative;
      .sitewide-piping .bottom;
      span {
        height: 68px;
        font-size: 32px;
        margin-right: 20px;
        background: #2d2d2d;
        color: white;
        display: inline-block;
        height: 68px;
        width: 68px;
        text-align: center;
        &:before {
          position: relative;
          top: 3px;
        }
      }
      a {
        height: 68px;
        background: #bfbfbf;
        display: inline-block;
        float: right;
        font-size: 18px;
        color: white;
        padding: 0 36px;
        @media @phone-only {
          width: 100%;
          text-align: center;
        }
      }
    }
    #content-column {
      min-height: 100px;
    }
    &.people {
      .profile-container {
        width: ~'calc(100% + 12px)';
        width: ~'-webkit-calc(100% + 12px)';

      }
      .clearfix;
    }
  }
}

#special-offer-form {
  input {
    float: left;
    margin: 5px 10px 0 0;
  }
}

.search-list {
  //margin: 0 -25px;
  li {
    background: #eee;
    border-bottom: 1px solid #fff;
    padding: 15px 25px;
    text-transform: uppercase;
    font-size: 13px;
    font-family: gotham_bookregular;
    strong {
      padding-right: 20px;
      font-family: gotham_boldregular;
    }
    &:nth-child(2n) {
      background:#e0e0e0;
    }
  }
}
