@import "main/styles/index.less";
@import "global.less";

.type-video-edit {
  .column-1 {
    padding: 0;
  }
  .column-2 {
    padding: 30px;
    background: #eee;
  }

  .sel, textarea {
    .sitewide-piping .bottom;
  }
}

.video-col-wrapper{
  background: @dark-grey;
}

.video-content{
  background: @dark-grey;
  color: @pink;
  padding: 60px 22px 14px 22px;
}

.video-description{
  color: white;
  padding: 0 2px 2px;
}

.video-content-box{
  background: @medium-dark-grey;
  color: white;
  font-size: 12px;
  overflow: hidden;
  padding: 12px;
  text-shadow: 1px 1px 0 rgba(37,37,37,0.4);
  text-transform: none;
}

.embed-no-select{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.embed-select{
  color: @light-grey;
  font-family: gotham_bookregular,Helvetica,sans-serif;
  font-size: 11px;
  text-transform: none;
  word-wrap: break-word;
}

iframe.sg-embed{
  height: 234px;
  width: 385px;
}

// Disable CC button in Video JS
// This is showing up in Safari and other browsers that have
// native HLS support, and cause videojs-hls-quality-selector to show the
// CC button instead of the Quality button.
.vjs-subs-caps-button {
  display: none;
}
