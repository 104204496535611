@import "main/styles/index.less";
@import "global.less";

.notifications-page {
  display: flex;

  @media @phone-only {
    flex-direction: column;
  }
}

.notifications-wrapper {
  display: flex;
  @media @phone-only {
    display: block;
  }
}

.feed-wrapper {
  flex-grow: 1;
  flex-basis: 66%;

  @media @phone-only {
    flex-grow: 0;
    flex-basis: auto;
  }
  .feed-filter {
    .horizontal-nav {
      margin-bottom: 1px;
      ul {
        li.dropdown {
          text-align: center;
          ul {
            display: table;
            width: 100%;
          }
          li {
            display: table-cell;
            width: 50%;
          }
          a:not(.active) {
            background: rgb(201, 201, 201);
          }
          a.active {
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
  @media @underlandscape {
    margin-top: 10px;
  }
}

.notifications-feed {
  flex-grow: 1;

  @media @phone-only {
    margin-left: 0px;
  }
  .content-box {

    margin-bottom: 1px;
    .content-box-content {
      padding: 10px;
    }
    .img-wrapper {
      width: 40px;
      float: left;
      img {
        margin: 0;
      }
    }
    .notification-tip-received {
      display: flex;
      flex-direction: row;
      padding: 0;

      .balance-delta {
        background-color: #84aa57;
        color: @white;
        flex-basis: 100px;
        flex-shrink: 0;
        font-size: 13px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: gotham_boldregular;
        @media @phone-only {
          flex-basis: 85px;
        }
      }
      .balance-out {
        background-color: #c9c9c9;
      }
      .balance-sotd {
        background-color: #df879f;
      }
      .notification-body {
        flex-grow: 1;
      }
      .notification-body {
        padding: 10px;
        &:before {
          display: none;
        }
        form {
          border-top: 0px;

          .say-thanks-container {
            margin: 0;
            padding: 0;
          }

          button {
            background: #8bb95e;
            color: #fff;
            font-size: 11px;
            line-height: 42px;
            padding: 0 19px;
            height: 42px;
            font-weight: normal;
            font-family: gotham_boldregular,Helvetica,sans-serif;
            display: none;
          }
          &.active {
            button {
              display: block;
            }
          }
        }
        .say-thanks {
          margin-top: 10px;
          margin-right: 0;
          &:empty {
            &:before {
              color: #aaa;
              content: attr(placeholder);
            }
          }
        }
      }
    }
  }
  .followed .notification-body:before {
      content: none;
  }
  .img-wrapper + .notification-body {
    margin-left: 55px;
  }
  .liked .notification-body:before { content: '\e010';}
  .mentioned .notification-body:before { content: '@';}
  .video .notification-body:before {content:'\ee016';}
  .generic_follow .notification-body:before { content: '\e011';}
  .selected-sotd .notification-body:before { content: '\e00e';}

  .notification-body {
    line-height: 1;

    &:before {
      content: '\e012';
      .icon-font;
      font-size: 20px;
      box-sizing: border-box;
      padding: 10px 0 0 10px;
      color: #fff;
      width: 40px;
      height: 40px;
      position: absolute;
      top: 10px;
      right: 10px;
      background: #c9c9c9;
    }
    h3 {
       font-family: gotham_boldregular;
       text-transform: uppercase;
       margin-bottom: 10px;
       font-size: 14px;
    }
    p {
      margin: 0 100px 0 0;
    }
    .time {
      font-family: gotham_bookregular;
      font-size: 10px;
      text-transform: uppercase;
    }
    .follow {
      float: right;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      &:before {
        padding-right: 5px;
      }
    }
  }
  @media @phone-only {
    .notification-body:before {
      content: none !important;
    }
    .notification-body .follow {
      padding: 0 14px;
      width: 40px;
      overflow: hidden;
      span {
        display: none;
      }
    }
  }
}

.feed .content-box .content-box-content.commented {
  padding: 12px;
  .notification-body {
    position: relative;
    padding-right: 24px;
  }
  .notification-body:before {
    .icon-font;
    position: absolute;
    right: 0;
    top: -10px;
    font-size: 30px;
    padding: 20px 0 0 20px;
    color: #c9c9c9;
    float: right;
    background: transparent;
  }
  &.NOTIFY_COMMENTED_POST {
    .notification-body:before {
      content: '\e00f';
    }
  }
  &.NOTIFY_COMMENTED_VIDEO {
    .notification-body:before {
      content: '\e016';
    }
  }
  &.NOTIFY_COMMENTED_ALBUM {
    .notification-body:before {
      content: '\e00e';
    }
  }

  // .img-wrapper {
  //   width: 48px;
  //   & + .notification-body {
  //     margin-left: 72px;
  //   }
  // }
}

.balance-wrapper {
  display: flex;
  flex-direction: row;
  flex-basis: 33%;
  @media @phone-only {
    flex-basis: auto;
  }
  @media @phone-to-landscape {
    margin-top: 10px;
  }
}
.balance-content {
  margin-right: 20px;
  z-index: 3;
  width: 100%;
  overflow: hidden;
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-family: gotham_boldregular;
  letter-spacing: 1px;
  display: flex;
  flex-direction: column;

  @media @phone-only {
    margin-right: 0px;
  }

  .balance-content-inner {
    background-color: #272526;
    flex-grow: 0;

    @media @phone-only {
      padding-top: 10px;
      padding-left: 10px;
      text-align: left;
    }
  }

  h2 {
      -webkit-font-smoothing: antialiased;
      position: relative;
      font-family: gotham_bookregular;
      font-size: 3vw;
      font-weight: bold;
      line-height: 1.3;
      letter-spacing: 4px;
      margin-bottom: 50px;
      margin-top: 3.4em; // vertical centering
      padding: 0;
      &:before, &:after {
        color: @medium-grey;
        font-size: 30px;
      }
      &:after {
        bottom: -45px;
      }
      &.account-empty {
        font-size: 14px;
        font-family: gotham_bookregular;
        margin-top: 85px;
        .add-message {
          color: @medium-grey;
          display: block;
          font-size: 9px;
          margin-top: 8px;
          @media @phone-to-landscape {
            font-size: 8px;
          }
        }
        &:before {
          top: -50px;
          @media @phone-to-landscape {
            font-size: 18px;
            top: -30px;
          }
        }
        &:after {
          bottom: -50px;
          @media @phone-to-landscape {
            bottom: -30px;
            font-size: 18px;
          }
        }
        @media @phone-only {
          color: @white;
        }
        @media @phone-to-landscape {
          margin-top: 50px;
          font-size: 12px;
        }
      }

      letter-spacing: 2px;
      font-size: 28px;
      margin-top: 70px;
      font-family: Helvetica, sans-serif;

      .balance-info {
        font-size: 11px;
        display: block;
        font-family: gotham_bookregular;
        @media @phone-only {
          display: inline;
          font-size: 16px;
          a {
            color: @white;
          }
        }
      }
      @media @phone-only {
          &:before, &:after {
              display: none;
          }
          color: @pink;
          display: inline;
          font-size: 16px;
          margin-bottom: 0;
          margin-top: 2.2em; // vertical centering
          text-align: left;
      }
      @media only screen and (min-width: 600px) and (max-width:767px) {
        font-size: 24px;
        &:before, &:after {
          font-size: 24px;
        }
      }
      @media @phone-to-landscape {
          margin-top: 55px; // vertical centering
          &:before, &:after {
            display: block;
          }
      }
      @media @landscape {
          margin-top: 75px; // vertical centering
      }
      @media @maximum {
          font-size: 45px;
          margin-top: 70px;
      }
  }
  p {
      margin: 0;
      line-height: 24px;
  }
  p {
      font-size: 14px;
      margin: 0em;
      @media @ipadmax {
          letter-spacing: 1px;
          font-size: 11px;
          font-size: 1.7vw;
          line-height: 1.7;
      }
  }
  a {
      text-decoration: none;
      color: #d793a5;
  }
  .affiliate-info {
    background-color: #272526;
    padding-top: 20px;
    @media @phone-only {
      padding-top: 10px;
    }
    @media @phone-to-landscape {
      padding-top: 50px;
    }
    p {
      font-size: 11px;
      letter-spacing: 1px;
      margin-left: 20px;
      margin-right: 20px;
      @media @phone-only {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    .affiliate-info-link {
      display: block;
      background-color: @white;
      color: @grey-text-darker;
      margin: 20px;
      display: flex;
      @media @phone-only {
        margin-left: 0px;
        margin-right: 10px;
      }
      input {
        flex-grow: 1;
        overflow-y: hidden;
        overflow-x: hidden;
        white-space: nowrap;
        flex-basis: 200px;
      }
      button {
        background-color: @white;
        color: @grey-text;
        font-size: 8px;
        flex-grow: 0;
        flex-basis: 75px;
      }
    }
  }
  .affiliate-share {
    background-color: #272526;
    padding-bottom: 40px;
    button {
      &:before {
        font-size: 25px;
      }
      border: 1px solid @white;
      border-radius: 50%;
      display: inline-block;
      height: 45px;
      width: 45px;
      padding: 0;
      margin-left: 10px;
      margin-right: 10px;
    }
    @media @phone-only {
      display: none;
    }
  }
  .referral-stats {
    background-color: #272526;
    font-size: 8px;
    color: @grey-text;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    @media @phone-only {
      margin-left: 0;
      margin-right: 0;
    }
    @media @maximum {
      font-size: 10px;
    }
    .referral-count {
      color: @pink;
    }
  }
  .w9-notice {
    color: @white;
    font-family: gotham_boldregular;
    font-size: 9px;
    line-height: 15px;
    letter-spacing: 2px;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 40px;

    @media @phone-only {
      margin: 0 0 20px 0;
    }
  }
  .balance-actions {
      flex-basis: 47px;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      text-align: center;

      button {
          font-size: 11px;
          flex-grow: 1;
          padding: 10px;
          font-family: gotham_bookregular;
          font-weight: bold;
          @media @phone-only {
            padding: 3px;
            font-size: 11px;
          }
          @media @phone-to-landscape {
              padding: 5px;
          }
      }
      .cash-out {
          color: #fff;
          background: @pink;
          &:hover {
              color: @pink;
              background: #fff;
          }
      }
      .add-money {
        background-color: #bd7084;
        color: #fff;
        &:hover {
            color: #bd7084;
            background: #fff;
        }
      }
  }
}

.notifications-cash-out {
  background-color: #2c2c2c;
  border: 12px solid #aeaeae;
  padding: 25px;
  text-align: center;
  width: 475px;
  @media @phone-only {
    padding: 10px;
    width: 100%;
  }

  .icon-close {
    color: #b4b4b4;
    position: absolute;
    right: 25px;
    top: 25px;
  }

  h2 {
    color: @white;
    font-family: gotham_bookregular;
    font-size: 14px;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
    padding-top: 0;
    margin-top: -10px;
    @media @phone-only {
      margin-top: 5px;
    }
  }
  h3 {
    color: @pink;
    font-family: gotham_bookregular;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 3px;
    .cash-out-amount {
      color: @white;
    }
    @media @phone-only {
      font-size: 14px;
    }
  }
  p {
    color: @lighter-grey;
    font-family: Georgia;
    font-size: 14px;
    letter-spacing: 1px;

    @media @phone-only {
      font-size: 11px;
    }
  }
  .payment-preference {
    background-color: #474747;
    padding: 10px;
    display: flex;
    font-size: 11px;
    letter-spacing: 1px;
    margin-top: 50px;
    margin-bottom: 20px;

    @media @phone-only {
      flex-direction: column;
      margin-top: 40px;
    }
    .must-update {
      color: @white;
      font-family: gotham_bookregular;
      font-weight: bold;
      text-transform: uppercase;
      width: 100%;
    }
    .icon-checkmark {
      position: relative;
      top: -3px;
      margin-bottom: -3px;
      @media @phone-only {
        margin-bottom: 0;
        position: absolute;
        top: initial;
      }
      &:before {
        background-color: @pink;
        border-radius: 10px;
        color: @white;
        display: block;
        font-size: 10px;
        height: 10px;
        padding: 4px;
        margin-right: 15px;
        width: 10px;
        @media @phone-only {
          padding: 2px;
          font-size: 8px;
          margin-right: 0;
        }
      }
    }
    .payment-preference-method {
      color: @pink;
      flex-grow: 1;
      font-family: gotham_bookregular;
      font-weight: bold;
      text-align: left;
      text-transform: uppercase;
      display: block;
      @media @phone-only {
        margin-left: 25px;
      }
    }
    .payment-preference-detail {
      color: @white;
      display: block;
      flex-grow: 1;
      font-family: gotham_bookregular;
      text-align: right;
      text-transform: uppercase;
      word-break: break-all;

      @media @phone-only {
        font-size: 8px;
        margin-left: 25px;
        margin-top: 10px;
        text-align: left;
      }
    }
  }
  .update-payment-preference {
    text-transform: uppercase;
    font-family: gotham_bookregular;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    @media @phone-only {
      font-size: 10px;
    }
  }
  .btn-cash-out {
    background-color: #6e8928;
    color: @white;
    display: block;
    font-family: gotham_bookregular, Helvetica, sans-serif;
    font-size: 14px;
    letter-spacing: 1px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 50px;
    margin-bottom: 0px;
    padding: 20px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    &:disabled {
      background-color: rgb(65, 65, 65);
      color: #828282;
    }

    @media @phone-only {
      padding: 10px;
      margin-top: 40px;
    }

    .print-size {
      color: @white;
      font-family: bebas_neueregular;
      font-size: 40px;
    }
    .print-price {
      color: #ffffff;
      font-family: gotham_bookregular;
      font-size: 20px;
      text-transform: uppercase;
    }
  }
}
